import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ServiceDetailsCard from './servicedetailscard';

function ProjectDetails( { title, body, cards, onCloseModal } ) {
    const [ show, setShow ] = useState( true );

    const handleClose = () => {
        setShow( false );
        // Call the onCloseModal prop when closing the modal
        if ( onCloseModal && typeof onCloseModal === 'function' ) {
            onCloseModal();
        }
    };

    const handleShow = () => {
        console.log( 'Modal show clicked' );
        setShow( true );
    };

    return (
        <>
            <Modal show={ show } onHide={ handleClose } className="wide-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{ title }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div style={ { "marginBottom": "5%", "fontSize": "large" } } >
                                { body }
                            </div>
                            { cards &&
                                cards.map( ( card ) => (
                                    <div key={ card.name } className="col-md-4 mb-3">
                                        <ServiceDetailsCard
                                            cardTitle={ card.name }
                                            cardBody={ card.content }
                                            cardImg={ card.img }
                                        />
                                    </div>
                                ) ) }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose }>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProjectDetails;
