import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Sidebar from './components/sidebar'
import Introduction from './components/introduction'
import About from './components/about'
import Services from './components/services';
import Reviews from './components/reviews'
import Project from './components/projects'
import Blog from './components/blog';
import Certificates from './components/certificates';
import Footer from './components/footer';
import Skills from './components/skills';
import OtherWorkGallery from './components/othergallery';
import Contact from './components/contact';
import Broshure from './components/broshure';
class App extends Component {

  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
          <Sidebar></Sidebar>
          <div id="colorlib-main">
            <Router>
              <Routes>
                <Route path="/" element={
                  <>
                    <Introduction></Introduction>
                    <About></About>
                    <Broshure></Broshure>
                    <Services></Services>
                    <Project></Project>
                    {/* <Blog></Blog> */ }
                    <Skills></Skills>
                    <OtherWorkGallery></OtherWorkGallery>
                    <Certificates></Certificates>
                    <Reviews></Reviews>
                    <Contact></Contact>
                    <Footer></Footer>
                  </>
                } />
              </Routes>
            </Router>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
