import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="colorlib-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="footer-widget">
                <p className="footer-line"></p>
                <h5 className="colorlib-footer">Abhijeet Verma | Construction Engineer | Freelancer | +91 877-009-3683</h5>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
