import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Image } from "react-grid-gallery";
export const images = [
    {
        src: "images/gallery/1.jpg",
        original: "images/gallery/1.jpg",
        width: 320,
        height: 174,
        tags: [
            { value: "Rendering", title: "Render" },
        ],
        caption: "Single family residence",
    },
    {
        src: "images/gallery/2.jpg",
        original: "images/gallery/2.jpg",
        width: 320,
        height: 212,
        caption: "Collaboration",
    },
    {
        src: "images/gallery/3.jpg",
        original: "images/gallery/3.jpg",
        width: 320,
        height: 212,
        caption: "Typical Details",
    },
    {
        src: "images/gallery/4.jpg",
        original: "images/gallery/4.jpg",
        width: 320,
        height: 213,
        caption: "Single family residence",
    },
    {
        src: "images/gallery/5.jpg",
        original: "images/gallery/5.jpg",
        width: 320,
        height: 183,
        caption: "Single family residence",
    },
    {
        src: "images/gallery/6.jpg",
        original: "images/gallery/6.jpg",
        width: 440,
        height: 320,
        tags: [ { value: "Plan", title: "Site plan" } ],
        caption: "Farmhouse site plan",
    },
    {
        src: "images/gallery/7.png",
        original: "images/gallery/7.png",
        width: 320,
        height: 190,
        caption: "toilet details",
    },
    {
        src: "images/gallery/8.jpg",
        original: "images/gallery/8.jpg",
        width: 320,
        height: 148,
        tags: [ { value: "Render", title: "Render" } ],
        caption: "Interior render",
    },
    {
        src: "images/gallery/9.jpg",
        original: "images/gallery/9.jpg",
        width: 420,
        height: 213,
        tags: [ { value: "Render", title: "Render" } ],
        caption: "Interior render",
    },
    {
        src: "images/gallery/10.png",
        original: "images/gallery/10.png",
        alt: "Big Ben - London",
        width: 648,
        height: 320,
        caption: "Typical details",
    },
    {
        src: "images/gallery/11.jpg",
        original: "images/gallery/11.jpg",
        alt: "Red Zone - Paris",
        width: 320,
        height: 113,
        tags: [ { value: "Render", title: "Render" } ],
        caption: "Bird eye view",
    },
    {
        src: "images/gallery/12.jpg",
        original: "images/gallery/12.jpg",
        alt: "Wood Glass",
        width: 513,
        height: 320,
        caption: "Typical Details",
    },
    {
        src: "images/gallery/13.jpg",
        original: "images/gallery/13.jpg",
        width: 320,
        height: 213,
        caption: "Landscape",
    },
    {
        src: "images/gallery/14.jpg",
        original: "images/gallery/14.jpg",
        width: 320,
        height: 194,
        caption: "Door Details",
    },
    {
        src: "images/gallery/15.jpg",
        original: "images/gallery/15.jpg",
        alt: "Cosmos Flower",
        width: 320,
        height: 213,
        caption: "Facade render",
    },
    {
        src: "images/gallery/16.jpg",
        original: "images/gallery/16.jpg",
        width: 471,
        height: 320,
        caption: "Night render",
    },
    {
        src: "images/gallery/17.jpg",
        original: "images/gallery/17.jpg",
        width: 320,
        height: 213,
        tags: [
            { value: "Family Creation", title: "Family Creation" },
            { value: "Revit Family", title: "Revit Family" },
        ],
        caption: "Revit Family",
    },
    {
        src: "images/gallery/18.jpg",
        original: "images/gallery/18.jpg",
        width: 320,
        height: 213,
        tags: [
            { value: "Drafting", title: "Drafting" },
            { value: "CAD", title: "CAD" },
        ],
        caption: "CAD Drafting",
    },
    {
        src: "images/gallery/19.jpg",
        original: "images/gallery/19.jpg",
        width: 320,
        height: 213,
        caption: "Render",
    },
    {
        src: "images/gallery/20.jpg",
        original: "images/gallery/20.jpg",
        width: 380,
        height: 213,
        tags: [
            { value: "Concept", title: "Concept" },
            { value: "Design", title: "Design" },
        ],
        caption: "Design Concept",
    },
    {
        src: "images/gallery/21.png",
        original: "images/gallery/21.png",
        width: 320,
        height: 179,
        tags: [ { value: "Render", title: "Render" } ],
        caption: "Render",
    },
    {
        src: "images/gallery/22.png",
        original: "images/gallery/22.png",
        width: 320,
        height: 215,
        tags: [ { value: "Render", title: "Render" } ],
        caption: "Render",
    },
    {
        src: "images/gallery/23.png",
        original: "images/gallery/23.png",
        width: 400,
        height: 320,
        tags: [
            { value: "Family Creation", title: "Family Creation" },
            { value: "Revit Family", title: "Revit Family" },
        ],
        caption: "Revit Family"
    },
    {
        src: "images/gallery/24.jpg",
        original: "images/gallery/24.jpg",
        width: 450,
        height: 320,
        caption: "Interior Render",
    },
];
export default function App() {
    const [ index, setIndex ] = useState( -1 );

    const currentImage = images[ index ];
    const nextIndex = ( index + 1 ) % images.length;
    const nextImage = images[ nextIndex ] || currentImage;
    const prevIndex = ( index + images.length - 1 ) % images.length;
    const prevImage = images[ prevIndex ] || currentImage;

    const handleClick = ( index, item ) => setIndex( index );
    const handleClose = () => setIndex( -1 );
    const handleMovePrev = () => setIndex( prevIndex );
    const handleMoveNext = () => setIndex( nextIndex );

    return (
        <div>
            <section className="colorlib-about" data-section="gallery">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-3 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                            <span className="heading-meta">Gallery</span><br />
                            <h2 className="colorlib-heading">Other works</h2>
                        </div>
                    </div>
                    <div>

                        <Gallery
                            images={ images }
                            onClick={ handleClick }
                            enableImageSelection={ false }
                        />
                        { !!currentImage && (
                            /* @ts-ignore */
                            <Lightbox
                                mainSrc={ currentImage.original }
                                imageTitle={ currentImage.caption }
                                mainSrcThumbnail={ currentImage.src }
                                nextSrc={ nextImage.original }
                                nextSrcThumbnail={ nextImage.src }
                                prevSrc={ prevImage.original }
                                prevSrcThumbnail={ prevImage.src }
                                onCloseRequest={ handleClose }
                                onMovePrevRequest={ handleMovePrev }
                                onMoveNextRequest={ handleMoveNext }
                            />
                        ) }
                    </div></div></section>
        </div>
    );
}