import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
            <div>
                <section className="colorlib-about" data-section="about">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                                    <div className="col-md-12">
                                        <div className="about-desc">
                                            <span className="heading-meta">About me</span><br />
                                            <h2 className="colorlib-heading">Who Am I?</h2>
                                            <p>As an accomplished BIM Professional with three years of dedicated expertise in Revit, AutoCAD, Navisworks, Lumion, Photoshop, and Archicad, I've consistently delivered top-tier design and engineering solutions. My proven track record encompasses successfully executing numerous professional courses on platforms like Udemy and Coursera, solidifying my commitment to staying at the forefront of industry advancements.</p>
                                            <p>My proficiency lies in BIM methodologies, where I excel in orchestrating multidisciplinary teams and implementing industry standards to ensure seamless integration between design and construction phases. I specialize in reducing errors and optimizing project efficiency, leveraging a deep understanding of BIM standards such as COBie, IFC, and LOD.</p>
                                            <p>Throughout my career, I've excelled in driving BIM Services, including Scan to BIM, PDF or CAD to BIM, BIM Coordination, Structural BIM, Rebar Detailing & Modeling, Architectural BIM, Architectural Drafting, and 3D Rendering. My detail-oriented approach ensures accuracy while remaining committed to staying updated with the latest technological advancements in BIM.</p>
                                            <p>I am eager to bring my expertise to forward-thinking organizations, contributing to the successful implementation of BIM strategies and enhancing project delivery. My passion lies in driving excellence in BIM processes and facilitating the seamless convergence of design and construction realms for optimal outcomes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
