import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons';

const skills = [
    { name: 'Revit', title: 'Revit', icon: 'images/skills/REVIT.png', isFontAwesome: false },
    { name: 'Autocad', title: 'Autocad', icon: 'images/skills/AUTOCAD.jpg', isFontAwesome: false },
    { name: 'NavisWorks', title: 'NavisWorks', icon: 'images/skills/NAVISWORKS.jpg', isFontAwesome: false },
    { name: 'BIM 360', title: 'BIM 360', icon: 'images/Bim_360.png', isFontAwesome: false },
    { name: 'Archicad', title: 'Archicad', icon: 'images/skills/ARCHICAD.png', isFontAwesome: false },
    { name: 'Lumion', title: 'Lumion', icon: 'images/skills/LUMION.png', isFontAwesome: false },
    { name: 'Enscape', title: 'Enscape', icon: 'images/skills/ENSCAPE.png', isFontAwesome: false },
    { name: 'Photoshop', title: 'Photoshop', icon: 'images/skills/PHOTOSHOP.png', isFontAwesome: false },
    { name: 'Unreal Engine', title: 'Unreal Engine', icon: 'images/skills/UNREAL_ENGINE.png', isFontAwesome: false },
    { name: 'ETABS', title: 'ETABS', icon: 'images/skills/ETABS.png', isFontAwesome: false }
];

const Skills = () => {
    return (
        <div>
            <section className="colorlib-work" data-section="skills">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                            <span className="heading-meta">My Skills</span><br />
                            <h2 className="colorlib-heading animate-box">Softwares Known</h2>
                        </div>
                    </div>

                    <div className="row">
                        { skills.map( ( skill, index ) => (
                            <div key={ index } className="col-md-2 col-sm-6 icon-container">
                                <div className="rotate-container">
                                    { skill.isFontAwesome ? (
                                        <FontAwesomeIcon icon={ faFontAwesome } size="3x" className="rotating-icon" />
                                    ) : (
                                        <img src={ process.env.PUBLIC_URL + '/' + skill.icon } alt={ skill.name } className="rotating-icon" />
                                    ) }
                                </div>
                                <div className="icon-title">{ skill.title }</div>
                            </div>
                        ) ) }
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Skills;
