import React, { Component } from 'react'

export default class Reviews extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="reviews">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">highlights</span><br />
                <h2 className="colorlib-heading animate-box">My reviews</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon-1">
                        <img style={ { maxWidth: '100%', height: 'auto' } } src={ process.env.PUBLIC_URL + '/images/customer-review-2.png' } alt='pic here' />
                      </div>
                      <div className="timeline-label">
                        <h2>Use archicad for construction drawings <span> Client - Fiverr</span></h2>
                        <p>"Abhijeet was in constant communication over the process of this drawing set and the turnaround time for revisions was more than satisfactory.
                          Abhijeet made certain that the final set was delivered correct and satisfactory which was greatly appreciated.
                          However, there were a few rough patches we had to navigate due to the quantity of drawing revisions and clarification of expectations.
                          All and all- a pleasant experience that resulted in a set that communicated what we needed.</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon-1">
                        <img style={ { maxWidth: '100%', height: 'auto' } } src={ process.env.PUBLIC_URL + '/images/customer-review.png' } alt='pic here' />
                      </div>
                      <div className="timeline-label">
                        <h2>Convert PDF floor plan to editable CAD file<span> Client - Upwork</span></h2>
                        <p> "Abhijeet is meticulous with his drafting service.
                          Have worked with him on numerous commercial and residential projects big and small, and very pleased with his delivery every time!"</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-pen2" />
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none">
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
