import React, { Component } from 'react';
import ProjectDetails from './projectdetails';

export default class Projects extends Component {
	state = {
		projects: [
			{
				id: 1, shares: 5, views: 21, likes: 10, image: '1.jpg', title: 'Single Family House', loc: 'https://autode.sk/3NVr3vn', content: {
					files: [
						{ name: 'SITE PLAN', content: '', img: 'images/projects/proj-files/1/01.jpg' },
						{ name: 'FIRST FLOOR PLAN', content: '', img: 'images/projects/proj-files/1/02.jpg' },
						{ name: 'SECOND FLOOR PLAN', content: '', img: 'images/projects/proj-files/1/03.jpg' },
						{ name: 'ROOF PLAN', content: '', img: 'images/projects/proj-files/1/04.jpg' },
						{ name: 'EXTERIOR ELEVATION', content: '', img: 'images/projects/proj-files/1/05.jpg' },
						{ name: 'EXTERIOR ELEVATION 2', content: '', img: 'images/projects/proj-files/1/06.jpg' },
						{ name: 'BUILDING SECTIONS', content: '', img: 'images/projects/proj-files/1/07.jpg' },
						{ name: 'DETAILS', content: '', img: 'images/projects/proj-files/1/08.jpg' },
						{ name: 'DETAILS 2', content: '', img: 'images/projects/proj-files/1/09.jpg' }
					]
				}
			},
			{
				id: 2, shares: 0, views: 63, likes: 22, image: '2.jpg', title: 'Modern House', loc: 'https://autode.sk/429SyY1',
				content: {
					files: [
						{ name: 'SITE PLAN', content: '', img: 'images/projects/proj-files/2/01.jpg' },
						{ name: 'FIRST FLOOR PLAN', content: '', img: 'images/projects/proj-files/2/02.jpg' },
						{ name: 'SECOND FLOOR PLAN', content: '', img: 'images/projects/proj-files/2/03.jpg' },
						{ name: 'ROOF PLAN', content: '', img: 'images/projects/proj-files/2/04.jpg' },
						{ name: 'EXTERIOR ELEVATIONS', content: '', img: 'images/projects/proj-files/2/05.jpg' },
						{ name: 'EXTERIOR ELEVATIONS 2', content: '', img: 'images/projects/proj-files/2/06.jpg' },
						{ name: 'BUILDING SECTIONS', content: '', img: 'images/projects/proj-files/2/07.jpg' },
						{ name: 'WALL SECTIONS', content: '', img: 'images/projects/proj-files/2/08.jpg' },
						{ name: 'DETAILS', content: '', img: 'images/projects/proj-files/2/09.jpg' },
						{ name: 'DETAILS 2', content: '', img: 'images/projects/proj-files/2/10.jpg' },
						{ name: 'SITE GATE', content: '', img: 'images/projects/proj-files/2/11.jpg' },
						{ name: 'DETAILS', content: '', img: 'images/projects/proj-files/2/12.jpg' }
					]
				}
			},
			{
				id: 3, shares: 0, views: 50, likes: 30, image: '3.jpg', title: 'Riverside Farm House', loc: '',
				content: {
					files: [
						{ name: 'SITE PLAN', content: '', img: 'images/projects/proj-files/3/1.jpg' },
						{ name: 'GROUND FLOOR PLAN', content: '', img: 'images/projects/proj-files/3/2.jpg' },
						{ name: 'ATTIC FLOOR PLAN', content: '', img: 'images/projects/proj-files/3/3.jpg' },
						{ name: 'ROOF PLAN', content: '', img: 'images/projects/proj-files/3/4.jpg' },
						{ name: 'FOUNDATION PLAN', content: '', img: 'images/projects/proj-files/3/5.jpg' },
						{ name: 'REFLECTED CEILING PLAN GROUND FLOOR', content: '', img: 'images/projects/proj-files/3/6.jpg' },
						{ name: 'INTERIOR RENDERING', content: '', img: 'images/projects/proj-files/3/7.jpg' },
						{ name: 'REFLECTED CEILING PLAN ATTIC FLOOR', content: '', img: 'images/projects/proj-files/3/8.jpg' },
						{ name: 'POOL PLAN', content: '', img: 'images/projects/proj-files/3/9.jpg' },
						{ name: 'SECTIONS/DETAILS', content: '', img: 'images/projects/proj-files/3/10.jpg' },
						{ name: 'ELEVATIONS', content: '', img: 'images/projects/proj-files/3/11.jpg' },
						{ name: 'ELEVATIONS 2', content: '', img: 'images/projects/proj-files/3/12.jpg' },
						{ name: 'SECTIONS', content: '', img: 'images/projects/proj-files/3/13.jpg' },
						{ name: 'SECTION 2', content: '', img: 'images/projects/proj-files/3/14.jpg' },
						{ name: 'SECTION 3', content: '', img: 'images/projects/proj-files/3/15.jpg' },
						{ name: 'SECTION 4', content: '', img: 'images/projects/proj-files/3/16.jpg' },
						{ name: 'ENLARGED PLAN & INTERIOR ELEVATIONS', content: '', img: 'images/projects/proj-files/3/17.jpg' },
						{ name: 'INTERIOR ELEVATIONS 2', content: '', img: 'images/projects/proj-files/3/18.jpg' },
						{ name: 'INTERIOR ELEVATIONS 3', content: '', img: 'images/projects/proj-files/3/19.jpg' },
						{ name: 'WALL ASSEMBLY', content: '', img: 'images/projects/proj-files/3/20.jpg' },
						{ name: 'FLOOR ASSEMBLY', content: '', img: 'images/projects/proj-files/3/21.jpg' },
						{ name: 'DETAILS GROUND FLOOR', content: '', img: 'images/projects/proj-files/3/22.jpg' },
						{ name: 'DETAILS GROUND FLOOR 2', content: '', img: 'images/projects/proj-files/3/23.jpg' },
						{ name: 'DETAILS GROUND FLOOR 3', content: '', img: 'images/projects/proj-files/3/24.jpg' },
						{ name: 'DETAILS', content: '', img: 'images/projects/proj-files/3/25.jpg' }
					]
				}
			},
			{
				id: 4, shares: 0, views: 31, likes: 21, image: '4.jpg', title: 'Farmhouse Architecture', loc: 'https://autode.sk/4aIeJZ4',
				content: {
					files: [
						{ name: 'SITE PLAN', content: '', img: 'images/projects/proj-files/4/1.jpg' },
						{ name: 'GROUND FLOOR PLAN', content: '', img: 'images/projects/proj-files/4/2.jpg' },
						{ name: 'FIRST FLOOR PLAN', content: '', img: 'images/projects/proj-files/4/3.jpg' },
						{ name: 'TERRACE PLAN', content: '', img: 'images/projects/proj-files/4/4.jpg' },
						{ name: 'ELEVATION-01', content: '', img: 'images/projects/proj-files/4/5.jpg' },
						{ name: 'ELEVATION-02', content: '', img: 'images/projects/proj-files/4/6.jpg' },
						{ name: 'SECTIONS', content: '', img: 'images/projects/proj-files/4/7.jpg' },
						{ name: 'SWIMMING POOL', content: '', img: 'images/projects/proj-files/4/8.jpg' },
						{ name: 'DOOR DETAILS', content: '', img: 'images/projects/proj-files/4/9.jpg' },
						{ name: 'WINDOW DETAILS', content: '', img: 'images/projects/proj-files/4/10.jpg' },
						{ name: 'CONCEPT SHEET', content: '', img: 'images/projects/proj-files/4/11.jpg' }
					]
				}
			},
			{
				id: 5, shares: 0, views: 40, likes: 12, image: '5.jpg', title: 'Community Building', loc: 'https://autode.sk/48Ja5YQ',
				content: {
					files: [
						{ name: 'COMMUNITY BUILDING', content: '', img: 'images/projects/proj-files/5/1.jpg' },
						{ name: 'STRUCTURE', content: '', img: 'images/projects/proj-files/5/2.jpg' },
						{ name: 'FOOTING', content: '', img: 'images/projects/proj-files/5/3.jpg' },
						{ name: 'SLAB PART-1', content: '', img: 'images/projects/proj-files/5/4.jpg' },
						{ name: 'BBS', content: '', img: 'images/projects/proj-files/5/5.jpg' },
						{ name: 'MATERIAL TAKEOFF', content: '', img: 'images/projects/proj-files/5/6.jpg' },
						{ name: 'BASE PLATE AND COLUMN', content: '', img: 'images/projects/proj-files/5/7.jpg' },
						{ name: 'SLAB PART-2', content: '', img: 'images/projects/proj-files/5/8.jpg' },
					]
				}
			},
			{
				id: 6, shares: 0, views: 40, likes: 21, image: '6.jpg', title: 'Farm House Structure', loc: 'https://autode.sk/3tzZosW',
				content: {
					files: [
						{ name: 'FOOTING LAYOUT', content: '', img: 'images/projects/proj-files/6/1.jpg' },
						{ name: 'PEDESTRAL COLUMN', content: '', img: 'images/projects/proj-files/6/2.jpg' },
						{ name: 'PLINTH BEAM', content: '', img: 'images/projects/proj-files/6/3.jpg' },
						{ name: 'GROUND FLOOR SLAB', content: '', img: 'images/projects/proj-files/6/4.jpg' },
						{ name: 'STAIR', content: '', img: 'images/projects/proj-files/6/5.jpg' },
						{ name: 'FIRST FLOOR BEAM', content: '', img: 'images/projects/proj-files/6/6.jpg' },
						{ name: 'FIRST FLOOR SLAB', content: '', img: 'images/projects/proj-files/6/7.jpg' },
						{ name: 'TERRACE COLUMN', content: '', img: 'images/projects/proj-files/6/8.jpg' },
						{ name: 'TERRACE BEAM', content: '', img: 'images/projects/proj-files/6/9.jpg' },
						{ name: 'TERRACE SLAB', content: '', img: 'images/projects/proj-files/6/10.jpg' },
						{ name: 'ROOF SECTION', content: '', img: 'images/projects/proj-files/6/11.jpg' },
						{ name: 'SCHEDULE', content: '', img: 'images/projects/proj-files/6/12.jpg' },
						{ name: 'BBS', content: '', img: 'images/projects/proj-files/6/13.jpg' }
					]
				}
			},
		],
		selectedProject: null,
		isViewModalOpen: false
	};

	handleIconClick = ( projectId, type ) => ( event ) => {
		event.preventDefault();
		if ( type === 'views' ) {
			const selectedProject = this.state.projects.find( ( project ) => project.id === projectId );
			console.log( "selected project ", selectedProject );
			// Use the callback function of setState
			this.setState( { selectedProject: selectedProject, isViewModalOpen: true }, () => {
				console.log( "selected state ", this.state );
			} );
		}
	};

	handleCloseModal = ( callback ) => {
		this.setState( { selectedProject: null, isViewModalOpen: false }, () => {
			if ( callback && typeof callback === 'function' ) {
				callback();
			}
		} );
	};

	handleLinkClick = ( projectId ) => {
		this.setState( ( prevState ) => ( {
			projects: prevState.projects.map( ( project ) =>
				project.id === projectId ? { ...project, views: project.views + 1 } : project
			)
		} ) );
	};

	render() {
		const { projects } = this.state;
		return (
			<div>
				<section className="colorlib-work" data-section="projects">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">My Work</span><br />
								<h2 className="colorlib-heading animate-box">Recent Projects</h2>
							</div>
						</div>

						{ projects.map( ( project ) => (
							<div key={ project.id } className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project" style={ { backgroundImage: `url(images/projects/${ project.image })` } }>
									<div className="desc">
										<div className="con">
											<h3>
												<a
													href={ project.loc }
													target="_blank"
													rel="noopener noreferrer"
													onClick={ () => this.handleLinkClick( project.id ) }
												>
													{ project.title }
												</a>

												<span>Click to See in 3d</span>
											</h3>
											<p className="icon">
												<span>
													<a href="#" onClick={ this.handleIconClick( project.id, 'views' ) }>
														View Files <i className="icon-eye" /> { project.views }
													</a>
												</span>
												<span>
													<a href="#" onClick={ this.handleIconClick( project.id, 'likes' ) }>
														Like <i className="icon-heart" /> { project.likes }
													</a>
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						) ) }
					</div>
				</section>
				{ this.state.isViewModalOpen &&
					<ProjectDetails
						title={ this.state.selectedProject.title }
						details={ this.state.selectedProject.content[ "details" ] }
						cards={ this.state.selectedProject.content.files }
						view={ this.state.isViewModalOpen }
						onCloseModal={ this.handleCloseModal }
					/> }
			</div>

		);
	}
}
