import React from 'react';

const Contact = () => {
    const [ formStatus, setFormStatus ] = React.useState( 'Send' );

    const onSubmit = async ( e ) => {
        e.preventDefault();
        setFormStatus( 'Submitting...' );

        const { name, email, message } = e.target.elements;
        const formData = {
            name: name.value,
            email: email.value,
            message: message.value,
        };

        try {
            const res = await sendEmail( formData );
            console.log( res );
            setFormStatus( 'Email sent successfully!' );

            // Clear the form fields on successful email sending
            name.value = '';
            email.value = '';
            message.value = '';

        } catch ( error ) {
            console.error( 'Error sending email:', error );
            setFormStatus( 'Error sending email. Please try again.' );
        }
    };

    const sendEmail = async ( emailDetails ) => {
        try {
            const response = await fetch( 'https://44.222.223.104:3001/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( emailDetails ),
                mode: 'no-cors',
            } );

            const result = await response.text();
            return result;
        } catch ( error ) {
            throw error;
        }
    };


    return (
        <div>
            <section className="colorlib-about" data-section="contact">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-3 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                            <span className="heading-meta">Contact me</span><br />
                            <h2 className="colorlib-heading"></h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="container mt-3">
                            <div className="row">
                                {/* Left side - Form */ }
                                <div className="col-md-6 mb-3">
                                    <h2 className="mb-3">Write me an email</h2>
                                    <form style={ { margin: "5%" } } onSubmit={ onSubmit }>
                                        <fieldset>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="name">
                                                    Name
                                                </label>
                                                <input className="form-control" type="text" id="name" required placeholder="Your Name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="email">
                                                    Email
                                                </label>
                                                <input className="form-control" type="email" id="email" required placeholder="Your Email" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="message">
                                                    Message
                                                </label>
                                                <textarea className="form-control" id="message" required placeholder="Your Message"></textarea>
                                            </div>
                                        </fieldset>
                                        <button className="btn btn-danger" type="submit">
                                            { formStatus }
                                        </button>
                                    </form>
                                </div>

                                {/* Right side - Google Map */ }
                                <div className="col-md-6">
                                    <h2>My Office location</h2>
                                    <br />
                                    <iframe
                                        title="Google Map"
                                        width="100%"
                                        height="400px"
                                        frameBorder="0"
                                        style={ { border: 0 } }
                                        src={ `https://www.google.com/maps/embed/v1/place?q=1265%2C%20Sector%20D%2C%20Sudama%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452009%2C%20India&key=AIzaSyC5k-XaVph4-XxGmLVMXHbMmIuas4f7ksQ` }
                                        allowFullScreen
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div></div></section></div>
    );
};

export default Contact;
