import React, { Component, useState } from 'react';
import ServiceDetails from './servicedetails';

const servicesData = [
    {
        title: 'ARCHITECTURAL BIM',
        iconClass: 'BIM Architecture_page-0001',
        description: `Architectural BIM Services create precise Revit models from sketches, scans, and PDFs, including custom content and 3D rendering, aligned with client's SOW and LOD specifications.`,
        details: "The Architectural BIM Services involves generating a detailed Revit BIM Architectural model using sketches, drawings, point cloud scans, and PDFs. We also cater to creating customized Revit content/family, along with offering 3D rendering and visualization services according to the client's Scope of Work (SOW) and specific Level of Detail (LOD) requirements. The range of services encompasses the creation of personalized Revit Family or BIM Content, the development of BIM Drafting models sourced from Point Cloud Data, PDFs, or 2D AutoCAD, as well as the identification and resolution of clashes. This involves crafting construction-ready models by harmonizing architectural models with Structural & MEPFS disciplines. With three years of experience and over 100 projects completed, I specialize in delivering precise and parameterized Revit Architectural BIM models. These models adhere to specified LOD, project prerequisites, and meet diverse client expectations across sectors such as Hotel, Residential, Commercial, Healthcare, Industrial, Community Centers, and more.",
        cards: [
            { name: `ARCHITECTURAL 3D MODELING`, content: 'Create precise and customizable Revit 3D BIM models in alignment with the specified Scope of Work, indicating trades to be incorporated or omitted, while meeting the necessary Level of Development (LOD) and detailed specifications such as sections, elevations, roofing, walls, doors, and other relevant components.', img: 'images/cards/a2b/1.jpg' },
            { name: '2D DRAFTING SERVICE', content: "Provide 2D CAD files in the .dwg format according to the client's specific standards, including the necessary Level of Detail, encompassing dimensions, annotations, layering, etc., as outlined in the client's standard format and Scope of Work. | Site Plan | Floor Plan | Cross Section | Elevation | Landscape | Finishing | Drawing | Working | Plan | Section Drawings | General Note | Excavation Drawing | As-Built drawings | Line Plan | Shop Drawings | Installation Drawings | Location Drawings | Location Plan |", img: 'images/cards/a2b/2.jpg' },
            { name: 'LANDSCAPE MODEL', content: 'Produce the site plan design for a project by illustrating the positioning of buildings and adjacent structures. This entails showcasing property elements such as terrain, the arrangement of driveways and pathways, gardens, swimming pools, and parking spaces to obtain project approvals.', img: 'images/cards/a2b/3.jpg' },
            { name: 'SCHEDULES', content: "I optimize architectural project management using Revit, crafting detailed schedules that enhance efficiency by organizing materials, quantities, and timelines. My expertise ensures precise documentation and seamless stakeholder coordination, tailoring schedules for informed decision-making. This elevates workflows, fostering smoother operations and communication across the project lifecycle.", img: 'images/cards/a2b/4.jpg' },
            { name: 'QUANTITY AND MATERIALTAKEOFF', content: 'Revit streamlines architectural material takeoff by extracting precise data from drawings and models, generating detailed reports on materials like concrete and steel. This process optimizes cost estimation, aids resource planning, and enhances project efficiency, crucial for successful construction management.', img: 'images/cards/a2b/5.jpg' },
        ]
    },
    {
        title: 'STRUCTURAL BIM',
        iconClass: 'icon-bulb',
        description: 'Specializing in Structural BIM Services, I expedite fabrication and construction with Revit modeling, offering precise shop drawings for steel and RCC, collaboration and iterative design modifications.',
        details: "My Structural BIM Services specialize in enhancing structural documentation and facilitating seamless collaboration through Revit structural modeling. This aids in expediting the fabrication and construction phases. I offer in-depth structural shop drawings encompassing steel columns, beams, and reinforcement specifics within reinforced concrete elements. These detailed drawings benefit the project teams by providing a clear visualization of the structure, enabling iterative modifications between design and detailed models, and fostering coordinated model-based designs.",
        cards: [
            { name: 'STRUCTURAL 3D MODELING', content: "Craft precise Revit 3D BIM models per project specifications, specifying included trades, meeting required LOD and detailing sections, elevations, roofing, walls and pertinent components. Structural BIM in Revit uses Autodesk's tools to generate detailed digital construction representations, integrating dimensions and materials for accurate 3D models. This enables enhanced visualization, analysis, and collaboration among experts, ensuring efficient projects with reduced errors and optimized designs.", img: 'images/cards/stru-BIM/1.jpg' },
            { name: 'CONSTRUCTION DOCUMENTATION', content: "Provide .dwg format 2D CAD files meeting client specifications, ensuring precision and adherence to specified Level of Detail. Include dimensions, annotations, and layering aligned with client standards and Scope of Work. Deliverables comprise | foundation | column layout | Floor Plan | Cross Section | Elevation | framing |Finishing details | abbreviations | G.A Drawing | General notes | Plan | Section | Shop Drawings | symbols | schedules | BBS |", img: 'images/cards/stru-BIM/2.jpg' },
            { name: 'REBAR DETAIL', content: 'Comprehensive sketches depicting the bar and steel in | beams | columns | joists | foundation | bolted connection | staircases | lintels | roof | structures | shelf angles | bearing plates | frames | wall partition supports | and workshop drawings.', img: 'images/cards/stru-BIM/3.jpg' },
            { name: 'QUANTITY TAKE-OFF', content: "I optimize architectural project management using Revit, crafting detailed schedules that enhance efficiency by organizing materials, quantities, and timelines. My expertise ensures precise documentation and seamless stakeholder coordination, tailoring schedules for informed decision-making. This elevates workflows, fostering smoother operations and communication across the project lifecycle.", img: 'images/cards/stru-BIM/4.jpg' },
            { name: 'FAMILY CREATION', content: "I specialize in providing BIM Object Creation Services, focusing on precise and parametric objects with a wide LOD range of 100 to 500. With a keen emphasis on visual accuracy, I excel in generating compact files in formats like .RVT, .RTE, and .IFC. My forte lies in tailored Revit Family Creation, offering both parametric and non-parametric object development and upkeep. These services cater extensively to architectural, structural, and MEP elements across diverse sectors. I craft Architectural Components, Furniture Products, Structural Elements, MEP Equipment, and more, serving Building and Construction Product Manufacturers' needs with customized solutions.", img: 'images/cards/stru-BIM/5.jpg' },
        ]
    },
    {
        title: 'MEP BIM',
        iconClass: 'icon-data',
        description: 'Creating 3D models and drafts in Revit for MEP-FP systems, developing Revit families for MEP-FP components, coordinating MEP-FP elements, generating shop drawings, schedules and quantity',
        details: "Creating 3D models and drafts in Revit for MEP-FP systems, developing Revit families for MEP-FP components, coordinating MEP-FP elements, generating shop drawings, and performing 4D simulation and 5D cost estimation.",
        cards: []
    },
    {
        title: 'CLASH DETECTION',
        iconClass: 'icon-data',
        description: 'Identify and report clashes in architectural, structural, mechanical, electrical, plumbing, and fire systems, including potential issues with other disciplines, in a comprehensive report.',
        details: `My Structural BIM Services specialize in enhancing structural documentation and facilitating seamless collaboration through Revit structural modeling. This aids in expediting the fabrication and construction phases. I offer in-depth structural shop drawings encompassing steel columns, beams, and reinforcement specifics within reinforced concrete elements. These detailed drawings benefit the project teams by providing a clear visualization of the structure, enabling iterative modifications between design and detailed models, and fostering coordinated model-based designs`,
        cards: [
            { name: 'CLASH REPORT GENERATION', content: 'Create a dashboard for clash reporting that encompasses both soft and hard clashes. This dashboard should display the ongoing progress made over time, specifically tailored for a gatekeeper meeting attended by MEP engineers, architects, general contractors, designers, and building owners.', img: 'images/cards/clash-det/1.jpg' },
            { name: 'SOFT AND HARD CLASHES', content: 'Detect and resolve both soft and hard conflicts within every discipline or trade that remained unnoticed during the separate design phases of architectural, structural, mechanical, electrical, and plumbing elements.', img: 'images/cards/clash-det/2.jpeg' },
            { name: 'CLASH DETECTION', content: 'Detect architectural, structural, mechanical, electrical, plumbing, and fire sprinkler clashes and produce a comprehensive report outlining clashes within and between architectural, structural, mechanical/HVAC, electrical, plumbing, and fire sprinkler disciplines. This report should cover clashes between the aforementioned disciplines as well as potential clashes with other disciplines like structural, mechanical, electrical, plumbing, fire sprinklers, and more.', img: 'images/cards/clash-det/3.webp' },
        ]
    },
    {
        title: 'REVIT FAMILY CREATION',
        iconClass: 'icon-data',
        description: 'I specialize in comprehensive Revit Families, offering BIM objects for architectural, structural, HVAC, plumbing, electrical, and fire-fighting components. I excel in Revit MEP and architecture family creation, providing accurate geometry and behavior',
        details: "I specialize in delivering comprehensive Revit Families covering architectural, structural, HVAC, plumbing, electrical, and fire-fighting components, tailored to meet specific Level of Detail (LOD) requirements from LOD 100 to LOD 500. These loadable and in-place families are digitally crafted BIM objects, accurately defining product geometry and behavior virtually. Our services cater to architects, MEP engineers, manufacturers, designers, and facility managers, providing precise BIM Object development or Revit architecture family creation in file formats like.RVT, .RTE, and .IFC. My proficiency extends to creating files in various software such as AutoCAD, ArchiCAD, Solidworks, Fusion 360, 3DS Max, and SketchUp, ensuring optimal model performance in the software's native environment.",
        cards: [
            { name: 'ARCHITECTURE', content: 'Furniture | Foundation | Door | Window | Casework | Railing | Entourage | Ceiling | Stairs | | Bathroom | Bedroom | Kitchen & Dining |', img: 'images/cards/rev-fam/1.jpg' },
            { name: 'STRUCTURE', content: 'Column | Steel Section | Structural Steel sections – Base plates, Gusset plates | Beam Section | Stiffener | Truss | Column | Steel Section | Structural Connections |', img: 'images/cards/rev-fam/2.jpg' },
            { name: 'HVAC AND MECHANICAL', content: 'HVAC Duct | HVAC Pipe | Duct Hanger | Radiators and Convectors | VAV terminal units | Air Terminal | Fan Coil Unit | Air Handling Unit | Diffusers, Registers, Grilles | Fire Damper | Fitting & Valve', img: 'images/cards/rev-fam/3.jpg' },
            { name: 'ELECTRICAL', content: 'Switch & Socket | Electrical equipment | Distribution Board | Transformer | Electrical devices | Lighting Fixtures | Fire Alarm Devices |', img: 'images/cards/rev-fam/4.jpg' },
            { name: 'PLUMBING', content: 'Pump | Valve | Fittings | Pipes & fixtures | Fixtures - Urinals, Wash Basins, Water Closet | Devices - Measuring devices, Gauges |', img: 'images/cards/rev-fam/5.jpg' },
        ]
    },
    {
        title: 'SHOP DRAWING',
        iconClass: 'icon-phone3',
        description: 'I create accurate shop drawings using Revit, aligning subcontractors, fabricators, and contractors to realize design concepts, aiding installation and fabrication per project standards.',
        details: "My shop drawing services ensure precise detailing based on Construction Documents (CD) following project standards. I employ Revit to create comprehensive shop drawings, aiding on-site installation and workshop fabrication. These drawings effectively communicate the visual design to all involved stakeholders. Their purpose is to align subcontractors, fabricators, and general contractors to collectively realize the design concept by assembling components and facilitating fabrication",
        cards: [
            { name: 'ARCHITECTURAL SHOP DRAWING', content: "Help the to architects create distinctive and structurally sound architectural plans for the building's components.", img: 'images/cards/shop-dr/1.jpg' },
            { name: 'STRUCTURAL SHOP DRAWING', content: 'It embodies the most intricate aspects of technical specifications and design purpose for the structural strength of the building, focusing on the smallest details of the concrete reinforcing bars.', img: 'images/cards/shop-dr/2.jpg' },
            { name: 'MECHANICAL SHOP DRAWING', content: 'Detailed drawings for mechanical, electrical, and plumbing elements encompassing HVAC systems, piping, ductwork, electrical panels, and various other components.', img: 'images/cards/shop-dr/3.png' },
            { name: 'AS-BUILTDRAWING', content: "Newly updated drawings were presented at the conclusion of the project, showcasing all modifications implemented throughout the construction phase.", img: 'images/cards/shop-dr/4.jpg' },
            { name: 'FACADE SHOP DRAWING', content: 'Describes the specifics of various outer structural elements of a building, including their style, dimensions, shape, positioning, and how they are joined together', img: 'images/cards/shop-dr/5.jpg' },
        ]
    },
    {
        title: 'SCAN TO BIM',
        iconClass: 'icon-layers2',
        description: 'I offer precise Scan to BIM Services, creating detailed As-Built BIM Models and 2D CAD representations, including walls, slabs, ceiling, roofs, electrical equipments, MEP equipments and furniture.',
        details: "My Scan to BIM building design and maintenance purposes. IM Services offers precise and parametric As-Built BIM Models and 2D CAD representations that illustrate the building's pipes, walls, slabs, roof layouts, facades, and surrounding landscapes. I offer Documentation for specific needs like Floor Plans, Exterior Elevations & Roof Plans, Cross-sections, and Site Layout Plans. Through my Point Cloud to BIM Modeling Services, I deliver models ranging from LOD 100 to 500, encompassing Architectural, Structural, and MEPFP Elements. My services also include Clash Detection, supporting clients in their Renovation, Refurbishment, Retrofit, or Reconstruction Projects.",
        cards: [
            { name: 'SCAN TO REVIT ARCHITECTURE', content: 'I transform the accurate data obtained from laser scanning into a detailed Level of Development (LOD) 500 3D Building Information Modeling (BIM) model that contains valuable information for professional building design and maintenance purposes.', img: 'images/cards/s2b/1.jpg' },
            { name: 'SCAN TO REVIT MEP', content: 'I assist in generating Revit models for mechanical, electrical, and plumbing systems based on point cloud data, specifically for renovations in commercial and industrial structures', img: 'images/cards/s2b/2.jpg' },
            { name: 'POINT CLOUD TO CAD', content: 'I transform point cloud information into customizable CAD blueprints utilized for the professional documentation and design phases of residential, commercial, and industrial structures.', img: 'images/cards/s2b/3.png' }
        ]
    },
    {
        title: 'CAD TO BIM',
        iconClass: 'icon-layers2',
        description: 'I expertly convert CAD drawings to precise BIM models with Revit/Navisworks, emphasizing client details to construction standards for collaborative, code-compliant outcomes. Proficient in 2D/3D software.',
        details: "I specialize in converting diverse CAD drawings (architectural, structural, MEP) into detailed BIM models using Autodesk's Revit and Navisworks. My expertise involves extracting accurate measurements and design details from reference files to create collaborative BIM models meeting construction standards. I prioritize understanding client-specific building components and detailing the level of detail (LOD) for each element. Proficient in various 2D and 3D software tools like Revit, Navisworks, Archicad, Dynamo Studio, Recap Pro, Lumion, Enscape and more, I ensure precise delivery aligning with codes and specifications. My experience ensures enriched, information-packed models for seamless subsequent procedures.",
        cards: [
            { name: 'CAD TO BIM', content: 'I can transform the project drawing into a BIM model in Revit if we have access to a 2D CAD (.dwg) file of the design.', img: 'images/cards/c2b/1.jpg' },
            { name: '3D CAD TO BIM', content: "I’m capable of transforming a 3D CAD file of the project drawing into a Revit BIM model, even if that's what you possess", img: 'images/cards/c2b/2.jpg' },
            { name: 'PDF TO BIM', content: 'I can create a Revit BIM model by converting the project drawings into a BIM model if we have a PDF file of the drawings.', img: 'images/cards/c2b/3.jpg' },
            { name: 'SKETCH TO BIM', content: "A hand-drawn project sketch is all that's required for me to transform it into a Revit BIM model.", img: 'images/cards/c2b/4.png' },
        ]
    },
    {
        title: 'RENDERING',
        iconClass: 'icon-phone3',
        description: "Specializing in swift and cost-effective 3D rendering using Revit, I transform clients' sketches and plans into architectural visualizations with expertise in Lumion and Enscape, catering to specific needs.",
        details: "I specialize in efficient architectural 3D rendering services, translating design concepts into realistic visualizations using Revit. My rapid, cost-effective approach creates detailed architectural models and immersive walkthroughs. Serving Owners, Developers, and Architecture Firms, I employ software like Lumion and Enscape. Clients submit sketches, plans, PDFs, or 3D models with material specs for Development or Redevelopment. Adapting to individual needs, we transform these inputs into high-quality visual representations, tailoring our services for precise visualization requirements.",
        cards: [
            { name: '3D MODEL', content: 'It embodies the project with the precise dimensions necessary for construction and visual appeal desired by building owners, developers, and architectural firms, akin to works of art.', img: 'images/cards/ren/1.jpg' },
            { name: 'EXTERIOR RENDERING', content: 'Displaying the external aspects of the property and its surrounding environment, whether planned for construction during daytime or nighttime, is depicted in highquality, photo-realistic images well ahead of any scheduled building activities.', img: 'images/cards/ren/2.jpg' },
            { name: 'INTERIOR RENDERING', content: 'Demonstrating the spatial arrangement, zoning, materials, textures, color schemes, lighting variations, furniture selection, decor preferences, and more, both during the day and night, using high-quality, realistic photographs.', img: 'images/cards/ren/3.jpg' },
            { name: 'ANIMATION AND WALKTHROUGH', content: 'Provides a comprehensive tour of the property as though it has been constructed already, presenting the exterior design and the surrounding areas through a meticulous walkthrough of the building.', img: 'images/cards/ren/4.jpg' }
        ]
    },
    {
        title: 'STRUCTURAL DESIGN',
        iconClass: 'icon-layers2',
        description: 'We deliver Artificial Intelligence (AI), Machine Learning (ML), and Internet of Things (IoT) implemented Digital twins for FM.',
        details: "Seismic designing of concrete structure according to IS code, designing of beam, column, footing and slab. Preparation of G.A drawing, detailing of rebar, BBS and schedule",
        cards: [
            // { name: '', content: '' },
        ]
    },
];

class Services extends Component {
    constructor ( props ) {
        super( props );
        this.state = {
            selectedService: servicesData[ 0 ],
        };
    }

    handleServiceClick = ( service ) => {
        console.log( 'Service clicked:', service );
        this.setState( {
            selectedService: service,
        } );
    };

    render() {
        return (
            <div>
                <section className="colorlib-about" data-section="services">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-4 col-md-offset-4 col-md-pull-4 animate-box" data-animate-effect="fadeInLeft">
                                <span className="heading-meta">What I do?</span><br />
                                <h2 className="colorlib-heading">My Expertise</h2>
                            </div>
                        </div>
                        <div className="row row-pt-md">
                            { servicesData.map( ( service, index ) => (
                                <div key={ index } className="col-md-4 col-sm-6 text-center animate-box">
                                    <div
                                        className={ `services color-1` }
                                        style={ { cursor: 'pointer' } }
                                        onClick={ () => this.handleServiceClick( service ) }
                                    >
                                        <span className="">
                                            { <img src={ process.env.PUBLIC_URL + '/images/services-thumb/' + ( index + 1 ) + '.png' } alt='pic here' /> }
                                            {/* { <i className={ service.iconClass } /> } */ }
                                        </span>
                                        <div className="desc">
                                            <div onClick={ () => this.handleServiceClick( service ) }>
                                                <h3>{ service.title }</h3>
                                            </div>
                                            <p style={ { fontSize: "12px" } }>{ service.description }</p>
                                            { this.state.selectedService && (
                                                <ServiceDetails
                                                    title={ this.state.selectedService.title }
                                                    body={ this.state.selectedService.details }
                                                    cards={ this.state.selectedService.cards }
                                                />
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) ) }
                        </div>
                    </div>
                </section >
            </div >

        );
    }
}
export default Services;