import React, { Component } from 'react'

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i /></nav>
          <aside id="colorlib-aside" className="border js-fullheight">
            <div className="text-center">
              <div className="author-img" style={ { backgroundImage: 'url(images/about.jpg)' } } />
              <h1 id="colorlib-logo"><a href="index.html">Abhijeet Verma</a></h1>
              <h4 id="colorlib-fetaure">BIM Engineer</h4>
              <span className="email"><i className="icon-mail"></i> vermaabhijeet684@gmail.com</span><br />
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active"><a href="#home" data-nav-section="home">Introduction</a></li>
                  <li><a href="#about" data-nav-section="about">About</a></li>
                  <li><a href="#broshure" data-nav-section="broshure">Broshure</a></li>
                  <li><a href="#services" data-nav-section="services">Services</a></li>
                  <li><a href="#projects" data-nav-section="projects">Projects</a></li>
                  <li><a href="#skills" data-nav-section="skills">Skills</a></li>
                  <li><a href="#gallery" data-nav-section="gallery">Gallery</a></li>
                  <li><a href="#certifications" data-nav-section="certifications">Cerifications</a></li>
                  <li><a href="#reviews" data-nav-section="reviews">Reviews</a></li>
                  <li><a href="#contact" data-nav-section="contact">Contact me</a></li>
                </ul>
              </div>
            </nav>
            <nav id="colorlib-main-menu">
              <ul>
                <li><a href="https://www.linkedin.com/in/abhijeetverma684/" target="_blank" rel="noopener noreferrer"><img src={ process.env.PUBLIC_URL + '/images/linkedin.svg' } alt='Linkedin' /></a></li>
                <li><a href="https://www.fiverr.com/verma_abhijeet" target="_blank" rel="noopener noreferrer"><img src={ process.env.PUBLIC_URL + '/images/fiverr.svg' } alt='Fiverr' /></a></li>
                <li><a href="https://www.upwork.com/freelancers/~013c008d066058179f" target="_blank" rel="noopener noreferrer"><img src={ process.env.PUBLIC_URL + '/images/upwork.svg' } alt='UpWork' /></a></li>
                <li><a href="https://www.picktime.com/89a0b775-d985-4dd5-815a-ac11e75bf05d" target="_blank" rel="noopener noreferrer"><img src={ process.env.PUBLIC_URL + '/images/picktime.jpg' } alt='Book me' /></a></li>
              </ul>
            </nav>
            <div className="colorlib-footer">
              <p><small>
                Thank you for visiting my profile. Let's Connect.
              </small></p>
              <p><small>
                Website Inspiration : Colorlib <i className="icon-heart" aria-hidden="true" /> <br></br>
              </small></p>
            </div>
          </aside>
        </div>
      </div>
    )
  }
}
