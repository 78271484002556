import React, { useState } from 'react';
import { CardImg } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ReactModalImage from 'react-modal-image';
import Modal from 'react-bootstrap/Modal';

function ServiceDetailsCard( { cardTitle, cardBody, cardImg } ) {
    const [ showFullText, setShowFullText ] = useState( false );
    const [ showImageModal, setShowImageModal ] = useState( false );

    const handleToggleText = () => {
        setShowFullText( !showFullText );
    };

    const handleShowImage = () => {
        setShowImageModal( true );
    };

    const handleCloseImage = () => {
        setShowImageModal( false );
    };

    const truncatedBody = showFullText ? cardBody : `${ cardBody.slice( 0, 50 ) }...`;

    return (
        <>
            <Card border="primary" className="mb-4 card-with-shadow">
                <Card.Img variant="top" src={ cardImg } />
                <Card.Body>
                    <Card.Title>{ cardTitle }</Card.Title>
                    <Card.Text style={ { fontSize: "12px" } }>{ truncatedBody }</Card.Text>
                </Card.Body>
                <Card.Footer className="text-center">
                    <div style={ { overflow: 'hidden' } }>
                        { cardBody.length > 50 && (
                            <Button variant="primary" onClick={ handleToggleText }>
                                { showFullText ? 'Less' : 'More...' }
                            </Button>
                        ) }
                        <Button variant="info" onClick={ handleShowImage } className="ml-2">
                            Show Image
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
            <Modal show={ showImageModal } onHide={ handleCloseImage } className='modal-dialog'>
                <Modal.Header closeButton>
                    <Modal.Title>{ cardTitle } - Showing image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={ cardImg } alt={ cardTitle } className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleCloseImage }>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ServiceDetailsCard;
