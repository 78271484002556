import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Brochure = () => {
    const [ modalShow, setModalShow ] = useState( false );
    const [ selectedImage, setSelectedImage ] = useState( '' );
    const imageUrls = [
        '/images/broshure/1.png',
        '/images/broshure/2.png',
        '/images/broshure/3.png',
        '/images/broshure/4.png',
        '/images/broshure/5.png',
        '/images/broshure/6.png'
    ];
    const handleImageClick = ( image ) => {
        setSelectedImage( image );
        setModalShow( true );
    };

    const handleClose = () => {
        setSelectedImage( '' );
        setModalShow( false );
    };

    return (
        <div>
            <section className="colorlib-about" data-section="broshure">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4 col-md-pull-4 animate-box" data-animate-effect="fadeInLeft">
                            <span className="heading-meta">Vivid Brochure Designs </span><br />
                            <h2 className="colorlib-heading">My work</h2>
                        </div>
                    </div>
                    <div className="row row-pt-md">
                        { imageUrls.map( ( imageUrl, index ) => (
                            <img
                                key={ index }
                                className="col-md-4 col-sm-6 text-center animate-box"
                                src={ imageUrl }
                                alt={ `Thumbnail ${ index + 1 }` }
                                onClick={ () => handleImageClick( imageUrl ) }
                                style={ { cursor: 'pointer' } }
                            />
                        ) ) }
                    </div>
                </div>
            </section >


            <Modal show={ modalShow } onHide={ handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { selectedImage && <img src={ selectedImage } alt="Selected Image" style={ { maxWidth: '100%' } } /> }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose }>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Brochure;