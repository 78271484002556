import React, { Component } from 'react';

export default class Certificates extends Component {
    state = {
        certificates: [
            {
                id: 1,
                name: 'Navisworks',
                image: 'images/certi/NAVISWORKS.png',
                pdfLink: 'https://drive.google.com/file/d/1hKsqxPZC5OcbSk4D2XyhR1TF_qNSjjao/view?usp=sharing',
            },
            {
                id: 2,
                name: 'Construction Management',
                image: 'images/certi/CONSTRUCTION-MANAGEMENT.png',
                pdfLink: 'https://drive.google.com/file/d/1i0By5Z-RA9hRblEAwcAk24TKBUyk7PYP/view?usp=sharing',
            },
            {
                id: 3,
                name: 'ETAB RC building design',
                image: 'images/certi/ETAB-RC-BUILDING-DESIGN.png',
                pdfLink: 'https://drive.google.com/file/d/1h6YJZj2nYv-eOt4ZAuVdYf6brFBz5RH2/view?usp=sharing',
            },
            {
                id: 4,
                name: 'Project Mgmt fundamentals',
                image: 'images/certi/PROJECT-PLANNING-FUND.png',
                pdfLink: 'https://drive.google.com/file/d/1i0BrxcSvNt0IZnwhS79z2Z7uanroXn3S/view?usp=sharing',
            },
            {
                id: 5,
                name: 'Sketchup & VRAY',
                image: 'images/certi/SKETCHUP.jpg',
                pdfLink: 'https://drive.google.com/file/d/1LhcR7z1jWwLllHM9ip627MkiIcF6fn_9/view?usp=sharing',
            },
            {
                id: 6,
                name: 'Revit architecture',
                image: 'images/certi/REVIT-ARCHIT.png',
                pdfLink: 'https://drive.google.com/file/d/1hEfuMX-sF18RcplTR7-t-raSbDi9j8xz/view?usp=sharing',
            },
            {
                id: 7,
                name: 'Revit Family creation',
                image: 'images/certi/REVIT-FAMILIES.png',
                pdfLink: 'https://drive.google.com/file/d/1hGKz0piHeFRnXymJIOtJFJ8J-0AXtLl4/view?usp=sharing',
            },
            {
                id: 8,
                name: 'Revit MEP',
                image: 'images/certi/REVIT-MEP.png',
                pdfLink: 'https://drive.google.com/file/d/1hJ2pJe5pxg7MKSf34cuIKq7nB44wwoHs/view?usp=sharing',
            },

        ],
        selectedCertificate: null,
    };

    openCertificate = ( pdfLink ) => {
        window.open( pdfLink, '_blank' );
    };

    closeCertificate = () => {
        this.setState( { selectedCertificate: null } );
    };

    render() {
        return (
            <div>
                <section className="colorlib-work" data-section="certifications">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                                <span className="heading-meta">My Certificates</span><br />
                                <h2 className="colorlib-heading animate-box">Certifications</h2>
                            </div>
                        </div>
                        <div className="row">
                            { this.state.certificates.map( ( certificate ) => (
                                <div key={ certificate.id } className="col-md-3 animate-box" data-animate-effect="fadeInLeft">
                                    <div className="img-responsive certificate">
                                        <img
                                            src={ certificate.image }
                                            alt={ certificate.name }
                                            onClick={ () => this.openCertificate( certificate.pdfLink ) }
                                        />
                                        <h3>{ certificate.name }</h3>
                                    </div>
                                </div>
                            ) ) }
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
